import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M3265 3843 c-196 -23 -436 -116 -580 -227 -146 -111 -305 -282 -305
-326 0 -34 17 -50 53 -50 23 0 48 20 131 104 216 218 449 332 731 356 457 39
881 -182 1115 -580 56 -96 108 -240 144 -402 4 -15 17 -35 31 -44 23 -15 27
-15 50 0 30 20 32 59 11 181 -41 226 -147 425 -319 598 -192 192 -423 317
-692 372 -80 16 -295 27 -370 18z m240 -23 c141 -14 218 -32 346 -80 314 -120
575 -360 701 -645 68 -153 109 -375 74 -404 -25 -21 -52 10 -61 67 -15 103
-93 302 -158 407 -141 229 -376 415 -631 500 -290 97 -622 78 -880 -49 -59
-30 -107 -48 -109 -42 -2 6 -15 1 -32 -12 -15 -12 -23 -22 -17 -22 6 0 14 5
17 10 3 6 12 10 18 10 7 0 -8 -13 -33 -29 -51 -32 -61 -32 -31 0 12 13 21 28
21 35 0 7 9 16 20 19 11 3 20 11 20 17 0 13 -49 1 -70 -16 -8 -7 -22 -16 -30
-21 -8 -4 -1 4 15 19 17 14 35 25 42 26 7 0 20 9 30 19 23 24 107 71 127 71 9
0 16 4 16 8 0 10 81 44 170 71 61 19 162 37 255 44 22 2 47 4 55 5 8 1 64 -3
125 -8z m-825 -335 c0 -2 -10 -9 -22 -15 -22 -11 -22 -10 -4 4 21 17 26 19 26
11z m-60 -39 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z
m-46 -55 c-2 -3 -12 3 -22 13 -16 17 -16 18 5 5 12 -7 20 -15 17 -18z m-81
-86 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z"/>
<path d="M2838 3663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2795 3650 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2827 3643 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M2770 3626 c0 -10 8 -16 22 -16 12 0 19 1 17 3 -2 1 -12 9 -21 16
-15 12 -18 11 -18 -3z"/>
<path d="M2833 3610 c-18 -11 -33 -22 -33 -25 0 -5 41 15 70 34 28 19 -5 11
-37 -9z"/>
<path d="M3330 3584 c-135 -13 -276 -52 -385 -107 -272 -136 -478 -404 -539
-699 -18 -89 -21 -285 -5 -374 44 -250 192 -483 409 -644 83 -62 224 -127 335
-156 359 -91 729 8 986 265 132 133 231 310 271 483 26 114 28 325 4 433 -93
430 -458 752 -902 795 -99 9 -108 10 -174 4z m-160 -222 c-183 -188 -269 -330
-325 -538 -11 -38 -35 -194 -35 -221 0 -2 -70 -3 -156 -3 l-157 0 7 66 c30
297 198 551 456 692 70 38 266 111 300 112 9 0 -32 -49 -90 -108z m533 66
c153 -51 267 -122 374 -230 135 -138 228 -336 240 -516 l6 -83 -154 3 -154 3
-7 75 c-12 121 -44 228 -100 340 -66 131 -130 217 -260 349 -117 117 -117 118
55 59z m-325 -220 c29 -6 87 6 88 20 1 6 2 50 3 97 0 47 5 85 10 85 18 0 205
-186 262 -261 142 -187 210 -399 196 -613 -16 -231 -134 -480 -309 -654 -73
-72 -137 -122 -157 -122 -7 0 -11 35 -11 96 l0 95 -47 -3 -48 -3 -3 -75 c-4
-124 1 -119 -71 -64 -173 130 -330 386 -376 613 -24 115 -16 316 15 416 56
178 187 371 350 513 l75 66 3 -101 c2 -86 5 -101 20 -105z m-553 -785 c8 -69
54 -212 91 -285 63 -126 193 -279 331 -390 35 -28 61 -53 59 -55 -8 -8 -178
38 -265 71 -118 46 -212 109 -303 204 -118 124 -182 256 -232 485 l-6 27 159
0 159 0 7 -57z m1495 39 c0 -35 -39 -170 -70 -246 -91 -218 -246 -367 -473
-454 -94 -36 -245 -78 -253 -69 -2 2 38 40 90 84 228 199 343 390 387 639 l11
64 154 0 c143 0 154 -1 154 -18z"/>
<path d="M3416 2975 c-109 -192 -98 -181 -206 -187 l-45 -3 0 -60 0 -60 108
-3 c70 -2 107 -6 107 -14 0 -10 -47 -195 -101 -397 -42 -156 -15 -119 183 249
l84 155 92 3 92 3 0 64 0 65 -107 2 c-140 2 -151 3 -158 9 -2 3 11 81 30 172
19 92 35 169 35 172 0 23 -29 -20 -114 -170z"/>
<path d="M1210 3053 c-39 -50 -9 -112 56 -113 38 0 74 36 74 73 0 65 -89 92
-130 40z"/>
<path d="M1456 3039 c-22 -32 -20 -52 7 -77 21 -20 34 -21 435 -28 389 -7 413
-6 432 11 26 24 26 67 -1 91 -20 18 -45 19 -439 22 l-418 4 -16 -23z"/>
<path d="M291 2846 c-43 -14 -60 -55 -40 -93 37 -70 139 -46 139 31 0 54 -44
81 -99 62z"/>
<path d="M552 2827 c-26 -27 -28 -54 -7 -84 21 -30 57 -31 942 -38 l762 -5 21
22 c26 28 25 51 -3 82 l-22 26 -780 10 c-429 5 -805 10 -836 10 -45 0 -60 -4
-77 -23z"/>
<path d="M1092 2594 c-15 -11 -22 -25 -22 -50 0 -48 30 -76 77 -72 66 6 93 76
47 122 -20 20 -73 20 -102 0z"/>
<path d="M1430 2592 c-81 -3 -110 -18 -110 -57 0 -12 11 -32 24 -44 24 -22 24
-22 429 -24 386 -2 406 -1 426 17 26 23 27 67 3 89 -15 14 -62 16 -357 19
-187 2 -374 2 -415 0z"/>
<path d="M4582 2493 c-12 -12 -28 -53 -42 -113 -104 -431 -366 -732 -750 -861
-125 -41 -239 -59 -385 -59 -324 0 -603 104 -782 290 -60 62 -72 70 -104 70
-40 0 -49 -11 -49 -59 0 -21 19 -46 83 -107 98 -96 183 -157 292 -212 174 -86
332 -122 539 -122 356 0 672 129 930 380 205 200 322 436 351 706 6 56 5 64
-16 83 -28 26 -41 26 -67 4z m62 -30 c11 -28 -14 -172 -49 -277 -195 -596
-868 -964 -1492 -816 -126 30 -233 76 -348 150 -84 54 -103 69 -178 141 -51
49 -56 57 -43 68 9 8 16 9 16 3 0 -5 11 -9 24 -8 13 0 22 5 19 9 -3 5 -11 6
-19 3 -8 -3 -17 1 -20 9 -5 12 -8 12 -22 -1 -14 -15 -17 -15 -28 1 -16 22 -1
57 21 49 8 -4 15 -11 15 -16 0 -6 7 -7 16 -4 12 5 15 2 10 -9 -3 -9 -2 -14 3
-11 4 3 17 -4 27 -16 45 -50 172 -141 247 -177 175 -82 295 -110 507 -118 247
-9 435 31 627 132 158 83 317 230 409 375 74 118 162 332 179 436 11 68 25 94
50 94 13 0 26 -7 29 -17z"/>
<path d="M2511 1764 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2640 1666 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M484 2376 c-21 -33 -15 -65 16 -87 23 -16 86 -18 855 -23 829 -6 831
-6 853 14 28 27 28 55 -1 84 l-23 23 -439 6 c-241 4 -620 7 -841 7 l-404 0
-16 -24z"/>
<path d="M1391 2174 c-26 -33 -26 -45 -2 -75 l19 -24 419 -5 418 -6 22 26 c30
33 29 51 -3 81 l-26 24 -413 3 -413 3 -21 -27z"/>
<path d="M993 1990 c-48 -20 -58 -87 -17 -119 54 -43 124 -10 124 57 0 26 -7
36 -32 52 -36 22 -43 23 -75 10z"/>
<path d="M1182 1965 c-28 -35 -28 -65 -1 -99 l20 -26 558 0 c546 0 558 0 576
20 25 28 23 76 -4 103 l-22 22 -553 4 -553 3 -21 -27z"/>
<path d="M2170 1670 l0 -130 25 0 c14 0 25 5 25 10 0 7 6 7 19 0 30 -16 50
-12 76 15 33 32 33 78 -1 111 -21 22 -31 25 -60 20 l-34 -7 0 56 c0 52 -1 55
-25 55 l-25 0 0 -130z m120 -50 c0 -23 -32 -43 -54 -34 -21 8 -20 51 1 63 23
12 53 -4 53 -29z"/>
<path d="M200 1673 c-25 -64 -46 -120 -48 -125 -2 -4 10 -8 26 -8 23 0 31 5
36 25 6 22 11 25 56 25 45 0 50 -3 56 -25 5 -20 13 -25 36 -25 16 0 28 4 26 9
-87 230 -93 241 -118 241 -23 0 -29 -11 -70 -117z m89 -3 c10 -29 9 -30 -19
-30 -28 0 -29 1 -19 30 6 17 15 30 19 30 4 0 13 -13 19 -30z"/>
<path d="M794 1776 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z"/>
<path d="M1194 1776 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
16z"/>
<path d="M1755 1778 c-3 -8 -4 -63 -3 -124 3 -109 3 -109 28 -109 23 0 25 4
28 48 l3 47 40 0 39 0 0 -50 c0 -47 2 -50 25 -50 l25 0 0 125 0 125 -25 0
c-23 0 -25 -3 -25 -50 l0 -50 -39 0 -40 0 -3 48 c-3 40 -6 47 -26 50 -12 2
-24 -3 -27 -10z"/>
<path d="M1080 1730 c0 -16 -4 -30 -10 -30 -5 0 -10 -9 -10 -20 0 -11 5 -20
10 -20 6 0 10 -13 10 -29 0 -47 17 -80 44 -87 14 -3 29 -3 34 0 14 9 2 46 -14
46 -9 0 -14 12 -14 35 0 24 5 35 15 35 8 0 15 9 15 20 0 11 -7 20 -15 20 -10
0 -15 10 -15 30 0 25 -4 30 -25 30 -21 0 -25 -5 -25 -30z"/>
<path d="M400 1620 c0 -79 0 -80 25 -80 23 0 25 3 25 49 0 51 18 78 44 68 10
-4 16 -24 18 -59 3 -46 6 -53 26 -56 21 -3 22 0 22 61 0 80 -10 90 -96 94
l-64 2 0 -79z"/>
<path d="M635 1688 c-53 -28 -61 -84 -17 -124 22 -21 34 -25 65 -21 53 7 77
31 77 79 0 33 -5 41 -32 58 -37 22 -63 25 -93 8z m73 -70 c3 -25 0 -28 -28
-28 -32 0 -47 18 -36 46 4 10 15 14 33 12 22 -2 29 -9 31 -30z"/>
<path d="M790 1620 c0 -79 0 -80 25 -80 25 0 25 1 25 80 0 79 0 80 -25 80 -25
0 -25 -1 -25 -80z"/>
<path d="M880 1620 c0 -79 0 -80 25 -80 23 0 25 3 25 49 0 51 18 78 45 67 11
-4 15 -21 15 -61 0 -52 1 -55 25 -55 24 0 25 2 25 64 0 79 -11 89 -96 93 l-64
2 0 -79z"/>
<path d="M1190 1620 c0 -79 0 -80 25 -80 25 0 25 1 25 80 0 79 0 80 -25 80
-25 0 -25 -1 -25 -80z"/>
<path d="M1280 1620 c0 -78 1 -80 25 -80 22 0 24 4 27 57 2 47 6 58 22 61 26
5 36 -15 36 -70 0 -44 2 -48 25 -48 24 0 25 3 25 58 0 82 -12 95 -94 99 l-66
2 0 -79z"/>
<path d="M1504 1688 c-33 -15 -47 -54 -34 -93 13 -38 53 -60 93 -49 28 6 33
-1 18 -25 -6 -10 -20 -12 -46 -8 -68 11 -87 -13 -30 -40 29 -14 41 -14 74 -4
35 10 42 17 51 52 6 22 10 70 8 107 l-3 67 -55 1 c-30 1 -65 -3 -76 -8z m86
-68 c0 -23 -32 -43 -54 -34 -22 9 -21 61 2 67 25 5 52 -11 52 -33z"/>
<path d="M1970 1661 c0 -76 10 -99 46 -111 42 -14 91 -5 104 20 6 10 10 44 10
74 0 52 -2 56 -23 56 -20 0 -23 -5 -23 -50 -1 -58 -15 -80 -42 -64 -12 8 -18
27 -20 63 -3 47 -5 51 -28 51 -21 0 -24 -4 -24 -39z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
